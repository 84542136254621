const SYMBOLS = {
  USD: '$', // US Dollar
  GBP: '£', // British Pound Sterling
  EUR: '€', // Euro
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  CRC: '₡', // Costa Rican Colón
  ILS: '₪', // Israeli New Sheqel
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
};

export const toCurrencySymbol = (code: keyof typeof SYMBOLS) => {
  return SYMBOLS[code];
};

export const getCurrencySymbols = () => SYMBOLS;
