import { FC } from 'react';

import { StoreProduct } from '~/app/lib/pageMetadata/types';
import { toCurrencySymbol } from '~/app/lib/utils/currency';
import Text from '~/app/components/Text';

export const resolveItemsPerRow = (
  totalItems: number,
  maxItemsPerRow: number
) => {
  let itemsPerRow = Math.min(maxItemsPerRow, totalItems);
  let itemsPerRowWithMaxOverflow;
  let maxTotalOverflow = 0;

  while (itemsPerRow) {
    const totalOverflow = totalItems % itemsPerRow;

    if (!totalOverflow) {
      return itemsPerRow;
    }

    if (totalOverflow > maxTotalOverflow) {
      maxTotalOverflow = totalOverflow;
      itemsPerRowWithMaxOverflow = itemsPerRow;
    }

    itemsPerRow--;

    if (itemsPerRow === 1) {
      break;
    }
  }

  return itemsPerRowWithMaxOverflow;
};

export const MerchText: FC<
  Pick<StoreProduct, 'name' | 'price' | 'priceCurrency'>
> = ({ name, price, priceCurrency }) => {
  return (
    <>
      <Text withEllipsis title={name}>
        {/* long text causes odd rendering overflow bug in horizontal scroller */}
        {name.slice(0, 32)}
      </Text>
      <Text weight="medium" margin="0.3rem 0 0">
        {toCurrencySymbol(priceCurrency as any)}
        {price?.toFixed(2)}
      </Text>
    </>
  );
};
