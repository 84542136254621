import React, { useMemo } from 'react';

import { useRegisterNavItem } from '../../ItemPageNav';
import { PageSectionComponent } from '../types';
import Box from '~/app/components/Box';

import HorizontalItemsSection, {
  HorizontalItemsSectionProps,
} from '../lib/HorizontalItemsSection';

import { MerchSectionProps } from './types';
import { MerchText } from './utils';
import { useSelector } from '~/app/lib/store/redux';
import { selectUserCountry } from '~/app/lib/store/session/selectors';

const MerchSection: PageSectionComponent<MerchSectionProps> = ({
  title,
  navTitle,
  sectionId,
  sectionIndex,
  items = [],
  territoryOverrides,
}) => {
  useRegisterNavItem({
    id: sectionId,
    text: navTitle,
    index: sectionIndex,
  });

  const userCountry = useSelector(selectUserCountry);

  const displayItems = territoryOverrides?.[userCountry]?.items || items;
  const displayTitle = territoryOverrides?.[userCountry]?.title || title;

  return (
    <Box testId="merch">
      <HorizontalItemsSection
        title={displayTitle}
        items={useMemo<HorizontalItemsSectionProps['items']>(() => {
          return displayItems.map(
            ({ name, link, image, price, priceCurrency }) => {
              return {
                key: link,
                image,
                href: link,
                testId: 'merchListItem',
                title: name,

                imageStyle: {
                  padding: '1rem',
                },

                text: (
                  <MerchText
                    name={name}
                    price={price}
                    priceCurrency={priceCurrency}
                  />
                ),
              };
            }
          );
        }, [displayItems])}
      />
    </Box>
  );
};

export default MerchSection;
